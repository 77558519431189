<script>
  import { notify } from '@/compositions/notification'
  const validate = require('validate.js')
  export default {
    name: 'reset-password-request',
    data () {
      return {
        formIsProcessing: false,
        error: false,
        form: {
          email_address: ''
        },
        message: {
          value: 'Enter your email address continue',
          isNegative: true
        },
        doneState: false,
        timeLeft: 30,
        redirecting: false
      }
    },
    computed: {
      formIsPopulated() {
        let form = this.form
        return !this.error && form.email_address.length > 0 && !validate({from: form.email_address}, { from: { email: true }})
      },
      messageClass() {
        return this.message.isNegative ? 'text-danger' : 'text-success'
      }
    },
    watch: {
      'form.email_address': function (val) {
        if (val.length === 0) {
          this.message.value = 'Email address must not be empty'
          this.message.isNegative = true
        } else if (validate({from: val}, { from: { email: true }})) {
          this.message.value = 'Must be a valid email address'
          this.message.isNegative = true
        } else {
          this.error = false
          this.message.value = null
          this.message.isNegative = false
        }
      },
    },
    methods: {
      requestReset() {
        // If the user presses enter on the form inputs it fires the signIn event, make sure the form is populated first
        if (!this.formIsPopulated) return

        this.formIsProcessing = true
        this.error = false
        // Submit a PUT request
        io.socket.put('/reset-password-request', this.form, (response, jwres) => {
          // Handle errors
          if (jwres.error) {
            // Display the error message
            this.message.value = jwres.body.replace(/[^a-zA-Z ]/g, '')
            this.message.isNegative = true
            // Enable the form inputs
            this.formIsProcessing = false
            // Disable "Please enter your password to continue"
            this.error = true
            // TODO: This should work but having to reable the input as it's currently disabled. Issue for vue in github?
            document.getElementById('requestReset').removeAttribute('disabled')
            // Return control to the user
            return
          }
          // Notify the user
          notify({ type: 'success', text: 'Request successfully sent' })
          // Prevent the user from using back with .replace
          setTimeout(() => { window.location.replace('/') }, 30000)
          // Trigger the users instructions
          this.doneState = true;
          (() => {
            let count = 30
            let timer = setInterval(() => {
              count--
              this.timeLeft = count
              if (count === 4) this.redirecting = true
              else if (count === 0) clearInterval(timer)
            }, 1000)
          })()
        })
      },
      back() {
        // Allow the user to use back with .assign
        window.location.assign('/')
      }
    }
  }
</script>

<template>

  <div class="vertical-center" autocomplete="on" v-cloak>
    <transition appear name="fade">
      <div class="container">
        <div class="row">
          <div class="col-12">

            <form class="form-centered" @submit.prevent="requestReset" v-if="!doneState">

              <!-- Company Logo -->
              <img src="https://cdn.angelaspirations.com/logo/AA_Logo_Black_Stacked_240_167px.png" alt="Angel Aspirations" class="img-responsive mb-3">

              <h5 class="mb-3">RESET PASSWORD REQUEST</h5>

              <small :class="[messageClass, 'mb-3']" v-if="message">{{ message.value }}</small>
              <small class="text-success mb-3" v-if="formIsPopulated">Please enter your email address</small>

              <!-- Email Field -->
              <div class="input-group mb-3">
                <label for="email_address" class="sr-only">Enter your email address</label>
                <span class="input-group-text" id="addon-email">
                  <font-awesome-icon :icon="['far', 'at']" />
                </span>
                <input type="email" id="email_address" class="form-control" placeholder="Enter your email address" aria-describedby="addon-email" autofocus :disabled="formIsProcessing" v-model="form.email_address" autocomplete="username">
              </div>

              <!-- Submit Button -->
              <button type="submit" class="btn btn-primary btn-lg btn-block mb-3" id="requestReset" :disabled="!formIsPopulated || formIsProcessing">SUBMIT <font-awesome-icon class="ml-1" :icon="['far', 'paper-plane']" fixed-width /></button>

              <!-- Back -->
              <small>
                <router-link :to="{ name: 'signin' }" title="Click to sign in to your user account">Back to sign in</router-link>
              </small>
            </form>

            <template v-else-if="redirecting">
              <p>Redirecting in {{ timeLeft }} seconds...</p>
            </template>

            <template v-else>
              <p>Please check your email for further instructions when resetting your account password.</p>
              <p>If the message has not arrived then please ensure that you entered the correct email address.</p>
              <p>You will be automatically redirected to the sign in page in {{ timeLeft }} seconds.</p>
            </template>

          </div> <!-- /col-12 -->
        </div> <!-- /row -->
      </div> <!-- /container -->
    </transition>
  </div> <!-- /vertical-center -->

</template>