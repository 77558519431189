import { createRouter, createWebHistory } from 'vue-router'
import SignIn from '../pages/auth/SignIn.vue'
import ResetPasswordRequest from '../pages/auth/ResetPasswordRequest.vue'

const routes = [
  { path: '/', name: 'signin', component: SignIn },
  { path: '/reset-password-request', name: 'reset-password-request', component: ResetPasswordRequest },
]

export const router = createRouter({
  history: createWebHistory(),
  routes,
})