import Noty from 'noty'
export function notify(givenOptions) {
  const defaultOptions = {
    theme: 'bootstrap-v4',
    type: 'error',
    layout: 'topCenter',
    timeout: 5000,
  }
  const newOptions = { ...defaultOptions, ...givenOptions }
  return new Noty(newOptions).show()
}